import Honeybadger from "@honeybadger-io/js";
import axios from "axios";
import useAuth from "@/composables/auth";
import useErrors from "@/composables/errors";
import { MOVEREADY_ORGANISATION_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref } from "vue";

const { toDisplayMessage } = useErrors();
const { getAccessToken, userId } = useAuth();

const organisationInvites = ref([]);
const isFetchingOrganisationInvites = ref(false);

const fetchOrganisationInvites = async (organisationId) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();
  isFetchingOrganisationInvites.value = true;

  try {
    const response = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/invites/organisations/${organisationId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    organisationInvites.value = response?.data || [];
    return organisationInvites.value;
  } catch (ex) {
    // A 404 is no invites, but return an empty array instead.
    if (ex?.response?.status === 404) {
      organisationInvites.value = [];
      return organisationInvites.value;
    }

    throw ex;
  } finally {
    isFetchingOrganisationInvites.value = false;
  }
};

const createOrganisationInvite = async ({
  organisationId,
  inviteeEmail,
  inviteeFirstName,
  inviteeLastName,
  inviteePhone,
  inviteeOfficeIds,
}) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();
  const invitePayload = {
    email: inviteeEmail,
    inviteeFirstName,
    inviteeLastName,
    phone: inviteePhone,
  };

  if (inviteeOfficeIds.length) {
    invitePayload.officesIds = inviteeOfficeIds;
  }
  try {
    await axios.post(
      `${MOVEREADY_ORGANISATION_API_URL}/invites/organisations/${organisationId}/users/${userId.value}/agents`,
      invitePayload,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Error creating an organisation invite",
      name: "organisationInvite.js",
      params: {
        organisationId,
        userId: userId.value,
        invitePayload,
      },
    });
    throw new Error(toDisplayMessage(ex, "organisation-invite/create"));
  }
};

const acceptOrganisationInvite = async ({
  inviteeUserId,
  organisationId,
  token,
}) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    await axios.patch(
      `${MOVEREADY_ORGANISATION_API_URL}/invites/organisations/${organisationId}/users/${inviteeUserId}/token/${token}/accept`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    // A 409 means that the invite has been accepted.
    if (ex.response?.status === 409) {
      return ex.response;
    }

    throw ex;
  }
};

const deleteOrganisationInvite = async (inviteId) => {
  const userAccessToken = await getAccessToken();
  const appCheckToken = await getAppCheckToken();

  try {
    await axios.delete(
      `${MOVEREADY_ORGANISATION_API_URL}/invites/${inviteId}/agents`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    Honeybadger.notify(ex, {
      message: "Error deleting an organisation invite",
      name: "organisationInvite.js",
      params: {
        inviteId,
      },
    });
    throw new Error(toDisplayMessage(ex, "organisation-invite/delete"));
  }
};

export default function useInvites() {
  return {
    acceptOrganisationInvite,
    createOrganisationInvite,
    deleteOrganisationInvite,
    fetchOrganisationInvites,
    isFetchingOrganisationInvites,
    organisationInvites,
  };
}
