import Honeybadger from "@honeybadger-io/js";
import axios from "axios";
import useAuth from "@/composables/auth";
import { MOVEREADY_ORGANISATION_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref, computed } from "vue";
import useErrors from "@/composables/errors";

const { getAccessToken } = useAuth();
const { toDisplayMessage } = useErrors();

const offices = ref([]);
const isFetchingOffices = ref(false);
const fetchOfficesErrorMessage = ref("");
const hasOffices = computed(() => offices.value.length > 0);
let hasFetchedOffices = false;

const fetchOffices = async (organisationId) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = await getAccessToken();
  isFetchingOffices.value = true;

  try {
    const response = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/${organisationId}/offices`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    offices.value = (response?.data || []).sort(sortOfficeNameAlphabetically);
  } catch (ex) {
    offices.value = [];
    Honeybadger.notify(ex, {
      name: "offices.js",
      message: `Failed to fetch offices`,
      params: {
        organisationId,
      },
    });
    fetchOfficesErrorMessage.value = toDisplayMessage(ex, "offices");
    throw new Error(fetchOfficesErrorMessage.value);
  } finally {
    isFetchingOffices.value = false;
    hasFetchedOffices = true;
  }
};

const sortOfficeNameAlphabetically = (a, b) => {
  if (a.organisationName < b.organisationName) return -1;
  if (a.organisationName > b.organisationName) return 1;
  return 0;
};

export default function useOffices() {
  return {
    fetchOffices,
    fetchOfficesErrorMessage,
    hasFetchedOffices,
    hasOffices,
    isFetchingOffices,
    offices,
  };
}
